<template>
  <div class="threshold-selector">
    <el-checkbox v-model="thresholdEnabled">{{ $tfo('confidence_threshold') }}</el-checkbox>
    <span class="parameter-default">{{ 'false' }}</span>
    <div v-if="thresholdEnabled" class="threshold-selector__item">
      <confidence-select-proxy :objects="objects" v-model="item[propName]" />
    </div>
  </div>
</template>
<script>
import Vue from 'vue';
import Component from 'vue-class-component';
import ConfidenceSelectProxy from './confidence.select.proxy';
import { ObjectsTypeSingleForm } from '@/store/objects/get.module';

@Component({
  name: 'object-threshold-selector',
  props: {
    objects: {
      type: String
    },
    item: {
      type: Object,
      required: true
    }
  },
  components: {
    ConfidenceSelectProxy
  }
})
export default class ThresholdSelector extends Vue {
  get propName() {
    return `${ObjectsTypeSingleForm[this.objects]}_threshold`;
  }

  get thresholdEnabled() {
    return this.item[this.propName] > 0;
  }

  set thresholdEnabled(value) {
    const { objectConfidenceThresholds } = this.$store.getters;
    const defaultThresholdPropName = `${ObjectsTypeSingleForm[this.objects]}_confidence_threshold`,
      defaultThreshold = 0.75,
      currentActiveThreshold = objectConfidenceThresholds[defaultThresholdPropName] || defaultThreshold;
    this.item[this.propName] = value ? currentActiveThreshold : 0;
  }
}
</script>

<style lang="stylus">

.threshold-selector__item {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}
</style>
