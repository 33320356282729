<template>
  <div>
    <div v-if="image">
      <polygon-draw-tool only-rect :image="image" :points="points" :disabled="disabled" @change="(v) => (points = v)" />
    </div>
    <div v-else>
      {{ $tt('cant_load_image') }}
    </div>
  </div>
</template>

<script>
import PolygonDrawTool from '@/components/polygon-draw/index';
import { pointsArrayToXGeometry, xGeometryToPointsArray } from '@/apps/common/filters';

export default {
  name: 'stream-settings-rot-form',
  props: {
    state: Object,
    item: Object,
    image: String,
    loadImage: Function,
    loading: Boolean,
    disabled: Boolean
  },
  created() {},
  mounted() {
    this.loadImage && this.loadImage();
  },
  components: {
    PolygonDrawTool
  },
  data: function () {
    return {
      screenshotDataUrl: null
    };
  },
  computed: {
    points: {
      get: function () {
        const result = this.item.stream_settings.rot;
        return xGeometryToPointsArray(result);
      },
      set: function (v) {
        this.item.stream_settings.rot = pointsArrayToXGeometry(v);
      }
    }
  }
};
</script>
