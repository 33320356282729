<template>
  <div>
    <common-tabs v-model="objects" :items="tabs" class="header-tabs"></common-tabs>

    <el-form
      ref="form"
      :size="$vars.sizes.form"
      :label-position="$vars.forms.label_position"
      :label-width="$vars.forms.label_width"
      :rules="rules"
      :disabled="disabled"
      :model="objectParams"
      v-if="hasDetector"
    >
      <el-form-item :label="$tf('parameter.filter_min_quality')" prop="filter_min_quality">
        <el-input name="filter_min_quality" size="small" v-model="objectParams.filter_min_quality" class="input--short-number"> </el-input>
        <span class="parameter-default">{{ defaultObjectParameters.filter_min_quality }}</span>
        <div class="parameter-description">{{ $tf('parameter.filter_min_quality_desc') }}</div>
      </el-form-item>

      <el-form-item :label="$tf('parameter.filter_min_size')" prop="filter_min_size">
        <el-input name="filter_min_size" v-model="objectParams.filter_min_size" size="small" class="input--short-number"></el-input>
        <span class="parameter-default">{{ defaultObjectParameters.filter_min_size }}</span>
        <div class="parameter-description">{{ $tf('parameter.filter_min_size_desc') }}</div>
      </el-form-item>

      <el-form-item :label="$tf('parameter.filter_max_size')" prop="filter_max_size">
        <el-input name="filter_max_size" v-model="objectParams.filter_max_size" size="small" class="input--short-number"></el-input>
        <span class="parameter-default">{{ defaultObjectParameters.filter_max_size }}</span>
        <div class="parameter-description">{{ $tf('parameter.filter_max_size_desc') }}</div>
      </el-form-item>

      <el-form-item :label="$tf('parameter.jpeg_quality')" prop="jpeg_quality">
        <el-input name="jpeg_quality" v-model="objectParams.jpeg_quality" size="small" class="input--short-number"></el-input>
        <span class="parameter-default">{{ defaultObjectParameters.jpeg_quality }}</span>
        <div class="parameter-description">{{ $tf('parameter.jpeg_quality_desc') }}</div>
      </el-form-item>

      <el-form-item :label="$tf('parameter.overall_only')" prop="overall_only">
        <el-checkbox name="overall_only" v-model="objectParams.overall_only" size="small"></el-checkbox>
        <span class="parameter-default">{{ defaultObjectParameters.overall_only }}</span>
        <div class="parameter-description">{{ $tf('parameter.overall_desc') }}</div>
      </el-form-item>

      <el-form-item :label="$tf('parameter.realtime_post_interval')" prop="realtime_post_interval">
        <el-input name="realtime_post_interval" v-model="objectParams.realtime_post_interval" size="small" class="input--short-number"></el-input>
        <span class="parameter-default">{{ defaultObjectParameters.realtime_post_interval }}</span>
        <div class="parameter-description">{{ $tf('parameter.realtime_post_interval_desc') }}</div>
      </el-form-item>

      <el-form-item :label="$tf('parameter.realtime_post_first_immediately')" prop="realtime_post_first_immediately">
        <el-checkbox name="realtime_post_first_immediately" v-model="objectParams.realtime_post_first_immediately" size="small"></el-checkbox>
        <span class="parameter-default">{{ defaultObjectParameters.realtime_post_first_immediately }}</span>
        <div class="parameter-description">{{ $tf('parameter.realtime_post_first_immediately_desc') }}</div>
      </el-form-item>

      <el-form-item :label="$tf('parameter.realtime_post_every_interval')" prop="realtime_post_every_interval">
        <el-checkbox name="realtime_post_every_interval" v-model="objectParams.realtime_post_every_interval" size="small"></el-checkbox>
        <span class="parameter-default">{{ defaultObjectParameters.realtime_post_every_interval }}</span>
        <div class="parameter-description">{{ $tf('parameter.realtime_post_every_interval_desc') }}</div>
      </el-form-item>

      <el-form-item v-if="hasAdvancedParameters">
        <el-button @click="toggleAdvancedParameters">{{ $tf('advanced_parameters') }}</el-button>
      </el-form-item>

      <template v-if="showAdvancedParameters">
        <el-form-item>
          <object-threshold-selector :objects="objects" :item="item" />
          <div class="parameter-description">{{ $tf('threshold_warning') }}</div>
        </el-form-item>
      </template>
    </el-form>
    <div v-else>{{ $tf('at_least_one_detector') }}</div>
  </div>
</template>

<script>
import { Component, Vue, Watch } from 'vue-property-decorator';
import { getNumberValidator, getNumberCorrector } from '@/apps/common/validator';
import { ObjectsTypeSingleForm } from '@/store/objects/get.module';
import ObjectThresholdSelector from '@/components/common/object.threshold.selector';

const baseRules = {
  filter_min_quality: [
    {
      trigger: 'change',
      tmessage: 'error.field.ufloat',
      validator: getNumberValidator({ required: true, min: -10000, max: 10000, float: true })
    }
  ],
  filter_min_size: [
    {
      trigger: 'change',
      tmessage: 'error.field.uint_max_min',
      validator: getNumberValidator({ required: true, min: 0, max: 10000 })
    }
  ],
  filter_max_size: [
    {
      trigger: 'change',
      tmessage: 'error.field.uint_max_min',
      validator: getNumberValidator({ required: true, min: 0, max: 10000 })
    }
  ],
  jpeg_quality: [
    {
      trigger: 'change',
      tmessage: 'error.field.uint_max_min',
      validator: getNumberValidator({ required: true, min: 0, max: 100 })
    }
  ],
  realtime_post_interval: [
    {
      trigger: 'change',
      tmessage: 'error.field.uint_max_min',
      validator: getNumberValidator({ required: true, float: true, min: 0, max: 1e6 })
    }
  ]
};
@Component({
  name: 'camera-analytics-form',
  props: {
    state: Object,
    item: Object,
    loading: Boolean,
    defaultParameters: Object,
    disabled: {
      type: Boolean,
      default: false
    },
    mode: {
      type: String,
      default: 'camera'
    }
  },
  components: {
    ObjectThresholdSelector
  }
})
export default class CameraAnalyticsForm extends Vue {
  rules = this.$applyRuleMessages(baseRules);
  objects = '';
  showAdvancedParameters = false;

  get hasAdvancedParameters() {
    return this.mode === 'camera';
  }

  get defaultObjectParameters() {
    const settingsKey = ObjectsTypeSingleForm[this.objects];
    return this.defaultParameters.stream_settings.detectors[settingsKey] || {};
  }

  get hasDetector() {
    return Object.values(this.item.stream_settings?.detectors || {}).some((v) => !!v);
  }

  get objectParams() {
    const settingsKey = ObjectsTypeSingleForm[this.objects];
    return this.item.stream_settings.detectors[settingsKey] || {};
  }

  set objectParams(v) {}

  get streamSettings() {
    return this.item.stream_settings;
  }

  get availableObjects() {
    const detectors = this.item.stream_settings.detectors,
      result = this.$store.getters.enabledObjects
        .map((v) => {
          return detectors[ObjectsTypeSingleForm[v]] ? v : null;
        })
        .filter((v) => !!v);
    return result;
  }

  get tabs() {
    return this.availableObjects.map((i) => ({ name: i, i18n: i }));
  }

  mounted() {
    this.availableObjectsChangeHandler();
    this.$emit('getForm', this.$refs.form);
  }

  @Watch('availableObjects')
  availableObjectsChangeHandler(v, p) {
    this.objects = this.objects || this.availableObjects[0] || '';
  }

  @Watch('objectParams.filter_min_quality')
  minQualityHandler(...args) {
    getNumberCorrector({ path: 'objectParams.filter_min_quality', float: true }).apply(this, args);
  }

  @Watch('objectParams.filter_min_size')
  minSizeHandler(...args) {
    getNumberCorrector({ path: 'objectParams.filter_min_size', float: false }).apply(this, args);
  }

  @Watch('objectParams.filter_max_size')
  maxSizeHandler(...args) {
    getNumberCorrector({ path: 'objectParams.filter_max_size', float: false }).apply(this, args);
  }

  @Watch('objectParams.jpeg_quality')
  jpegQualityHandler(...args) {
    getNumberCorrector({ path: 'objectParams.jpeg_quality', float: true }).apply(this, args);
  }

  @Watch('objectParams.start_stream_timestamp')
  startStreamHandler(...args) {
    getNumberCorrector({ path: 'objectParams.start_stream_timestamp', float: true }).apply(this, args);
  }

  @Watch('objectParams.realtime_post_interval')
  realtimePostHandler(...args) {
    getNumberCorrector({ path: 'objectParams.realtime_post_interval', float: true }).apply(this, args);
  }

  toggleAdvancedParameters() {
    this.showAdvancedParameters = !this.showAdvancedParameters;
  }

  validate(cb) {
    return this.$refs.form.validate(cb);
  }
}
</script>
